import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MDXProvider } from '@mdx-js/react';

import Head from '@/components/Head';
import NavBar from '@/components/NavBar';
import BgImage from '@/components/BgImage';
import Footer from '@/components/Footer';
import { heroImg, heroImgWrapper } from '@/styles/hero.module.css';

interface Post {
  body: string;
  frontmatter: {
    slug: string;
    title: string;
    date: string;
    image: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
  };
}

interface PageData {
  post: Post;
}

const mdxComponents = {
  p: ({ children }) => <p className="body-text mb-4 text-left">{children}</p>,
};

const NewsPage: React.FC<PageProps<PageData>> = ({
  data: {
    post: {
      body,
      frontmatter: { title, date, image },
    },
  },
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Head title={`${title} | ${t('news.news')} | ${t('kota_olive_oil')}`} />

      <NavBar />

      <header style={{ paddingTop: '74px' }}>
        <div className="w-full h-full d-flex relative -z-1">
          {/* Fix for borked lighthouse tooling expecting content and failing to give score */}
          <div className="absolute left-0 top-0" aria-hidden>
            Kota
          </div>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="Boce ulja"
            loading="eager"
            className={heroImgWrapper}
            imgClassName={heroImg}
          />
        </div>
      </header>
      {/* Hero with it's own bg image */}
      <main className="relative text-white">
        <BgImage />
        <section className="py-16 md:py-32 lg:py-48 w-full flex px-4">
          <div className="max-w-screen-lg mx-auto text-center">
            <div className="text-md font-sans uppercase mb-8 text-right">
              <strong className="text-uppercase font-bold">
                {t('news.published')}:
              </strong>{' '}
              {date}
            </div>
            <h1 className="text-4xl xl:text-6xl text-gold-default font-serif uppercase">
              {title}
            </h1>
            <hr className="text-gold-default w-full my-16" />
            <MDXProvider components={mdxComponents}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
            <Link to="/news" className="btn mt-10">
              {t('news.more_news')}
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default NewsPage;

export const query = graphql`
  query ($language: String!, $frontmatter__slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    post: mdx(
      frontmatter: { slug: { eq: $frontmatter__slug }, lang: { eq: $language } }
    ) {
      body
      frontmatter {
        slug
        title
        date(formatString: "YYYY-MM-DD")
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
